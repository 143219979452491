import {ReactNode, useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {useSalgsoppgaveJob} from "./SalgsoppgaveJobProvider";
import {JobStatus} from "../enums/JobStatus";
import LoadingPage from "../pages/LoadingPage/LoadingPage";
import MainTitle from "../components/MainTitle";
import HeadingDescriptor from "../components/HeadingDescriptor";
import MainButton from "../components/MainButton";
import {Outlet, useNavigate} from "react-router";

const DashboardWrapper = () => {
    const navigate = useNavigate();

    const {id} = useParams()
    const {salgsoppgaveJob, loadSalgsoppgaveJob} = useSalgsoppgaveJob()
    const [showLoading, setShowLoading] = useState<boolean>(true)

    useEffect(() => {
        id && loadSalgsoppgaveJob(id)
    }, [id]);

    useEffect(() => {
        if (
            salgsoppgaveJob?.jobStatus == JobStatus.LLM_IN_PROGRESS ||
            salgsoppgaveJob?.jobStatus == JobStatus.FAILED ||
            salgsoppgaveJob?.jobStatus == JobStatus.COMPLETED
        ) {
            setShowLoading(false)
        }
    }, [salgsoppgaveJob]);

    return (
        <>
            {showLoading ? (
                <LoadingPage/>
            ) : (
                <>
                    {salgsoppgaveJob && salgsoppgaveJob.jobStatus !== JobStatus.FAILED && (
                        <div className="flex">
                            <div className="w-full">
                                <Outlet />
                            </div>
                        </div>
                    )}

                    {salgsoppgaveJob && salgsoppgaveJob.jobStatus == JobStatus.FAILED && (
                        <div className={"w-full mt-[50vh] -translate-y-[50%] flex flex-col justify-center items-center"}>
                            <div className={"max-w-[500px] bg-red-50 p-4 shadow-md rounded-md"}>
                                <MainTitle className={"mb-2"}>Oisann, her gikk noe galt</MainTitle>
                                <HeadingDescriptor>
                                    Kunstig intelligens er ikke perfekt.
                                    Noen ganger oppfører den seg litt annerledes, slik som i dette tilfellet.
                                    Her er grunnen til at det gikk galt denne gangen:
                                </HeadingDescriptor>
                                <div className={"bg-red-300 mt-5 p-2 rounded-md opacity-70 font-bold"}>
                                    {salgsoppgaveJob.failedReason}
                                </div>
                            </div>

                            <MainButton className={"mt-10"} onClick={() => navigate("/")}>Prøv på nytt</MainButton>
                        </div>
                    )}
                </>
            )}
        </>
    );
};

export default DashboardWrapper;
