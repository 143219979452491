import {CheckBadgeIcon} from "@heroicons/react/24/solid";
import TooltipText from "./TooltipText";

const ProFeatures = () => {
    return (
        <>
            <h3 className={"text-gray-500 uppercase font-semibold text-sm mb-5"}>
                Alt som allerede er gratis, pluss:
            </h3>

            <div className={"grid grid-cols-2"}>
                <ul className={"space-y-4 mx-2"}>
                    <li className={"flex flex-row font-bold text-md gap-x-2"}>
                        <CheckBadgeIcon className={"w-5 text-green-500"}/> 25 salgsoppgaver per
                        mnd.
                    </li>
                    <li className={"flex flex-row font-bold text-md  gap-x-2"}>
                        <CheckBadgeIcon className={"w-5 text-green-500"}/> Lagre favorittboliger
                    </li>
                    <li className={"flex flex-row font-bold text-md gap-x-2"}>
                        <CheckBadgeIcon className={"w-5 text-green-500"}/> Sammenlign boliger
                    </li>
                </ul>


                <ul className={"space-y-4 mx-2"}>
                    <li className={"flex flex-row font-bold text-md"}>
                        <CheckBadgeIcon className={"w-5 mr-2 text-green-500"}/>
                        <TooltipText
                            tooltip={"Få eksklusiv tilgang til nye funksjoner før de blir tilgjengelige for andre brukere."}>
                            Beta tilgang
                        </TooltipText>
                    </li>
                    <li className={"flex flex-row font-bold text-md"}>
                        <CheckBadgeIcon className={"w-5 mr-2 text-green-500"}/> Prioritert kundeservice
                    </li>
                </ul>
            </div>

            <h3 className={"text-gray-500 uppercase font-semibold text-sm mt-8 mb-5"}>
                Kommer snart:
            </h3>
            <div className={"grid grid-cols-2"}>
                <ul className={"space-y-4 mx-2"}>
                    <li className={"flex flex-row font-bold text-md"}>
                        <CheckBadgeIcon className={"w-5 mr-2 text-gray-500"}/>
                        <TooltipText tooltip={"Tilpass dataene som hentes ut fra salgsoppgaven etter dine behov"}>
                            Tilpassede dashboard
                        </TooltipText>
                    </li>
                    <li className={"flex flex-row font-bold text-md"}>
                        <CheckBadgeIcon className={"w-5 mr-2 text-gray-500"}/>
                        <TooltipText tooltip={"Still spørsmål om boligen, og AI finner svarene i salgsoppgaven"}>
                            Chat med salgsoppgaven
                        </TooltipText>
                    </li>
                </ul>


                <ul className={"space-y-4 mx-2"}>
                    <li className={"flex flex-row font-bold text-md"}>
                        <CheckBadgeIcon className={"w-5 mr-2 text-gray-500"}/>
                        <TooltipText
                            tooltip={"Få et estimat på hva det vil koste å utbedre feilene fra tilstandsrapporten"}>
                            Kostnadsestimat
                        </TooltipText>
                    </li>
                </ul>
            </div>
        </>
    )
}

export default ProFeatures