import { ReactNode } from "react";
import {AlternativeSpinner} from "./AlternativeSpinner";

interface MainButtonProps {
    children?: ReactNode;
    className?: string;
    loading?: boolean
    onClick?: () => void;
}

const MainButton = ({ children, className, loading, onClick }: MainButtonProps) => {
    return (
        <button
            type="submit"
            className={
                "bg-gradient-to-r from-[#6DD1A5] to-[#669080] mx-3 px-4 py-2 text-white rounded-3xl hover:opacity-75 transition-all " +
                className
            }
            onClick={onClick}
        >
            {loading ? (
                <div className={"flex flex-col justify-center items-center "}>
                    <AlternativeSpinner />
                </div>

            ) : (
                <>
                    {children}
                </>
            )}

        </button>
    );
};

export default MainButton;
