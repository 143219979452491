import MainTitle from "../../components/MainTitle";
import Tag from "../../components/Tag";
import MetadataSection from "./components/MetadataSection";
import StateSection from "./components/StateSection";
import TechnicalStateSection from "./components/TechnicalStateSection";
import Skeleton, {SkeletonTheme} from "react-loading-skeleton";
import {useSalgsoppgaveJob} from "../../providers/SalgsoppgaveJobProvider";
import {useEffect} from "react";
import {Link, useParams} from "react-router-dom";
import {motion} from "framer-motion"
import {JobStatus} from "../../enums/JobStatus";
import RemarksSection from "./components/RemarksSection";
import PageHeading from "../../components/PageHeading";
import {useNavigate} from "react-router";
import {useSubscriptionProvider} from "../../providers/SubscriptionProvider";

const MainDashboard = () => {
    const navigate = useNavigate();
    const {validSubscription} = useSubscriptionProvider()

    const {salgsoppgaveJob} = useSalgsoppgaveJob()

    return (
        <SkeletonTheme baseColor={"#3d7364"} highlightColor={"#4c8e6a"}>
            <div className="container mx-auto flex flex-col mt-5 text-left justify-center">
                <button
                    className={"flex"}
                    onClick={() => navigate(-1)}
                >
                    Tilbake
                </button>

                {/*------------------------- ADDRESS INFORMATION -------------------------*/}
                {(salgsoppgaveJob?.salgsoppgave?.gateNavn && salgsoppgaveJob?.salgsoppgave?.gateNummer && salgsoppgaveJob.salgsoppgave?.postNummer && salgsoppgaveJob.salgsoppgave?.postSted) ? (
                    <>
                        <MainTitle>{salgsoppgaveJob.salgsoppgave.gateNavn} {salgsoppgaveJob.salgsoppgave.gateNummer}</MainTitle>
                        <Tag>{salgsoppgaveJob.salgsoppgave.postNummer} {salgsoppgaveJob.salgsoppgave.postSted}</Tag>
                    </>
                ) : (
                    <div className={"w-64"}>
                    {salgsoppgaveJob?.jobStatus === JobStatus.COMPLETED ? (
                        <>
                        <p className={"font-bold"}>FEILET</p>
                        </>
                        ) : (
                            <>
                                <Skeleton height={30} count={1}></Skeleton>
                                <Skeleton width={150} height={20} count={1}></Skeleton>
                            </>
                        )}
                    </div>
                )}

                {/*------------------------- AI READING TAG -------------------------*/}
                {(salgsoppgaveJob === undefined || salgsoppgaveJob.jobStatus !== JobStatus.COMPLETED) && (
                    <motion.div
                        animate={{opacity: [1, 0.5, 1]}}
                        transition={{repeat: Infinity, duration: 2}}
                        className={"mt-5 right-16 top-16 bg-main text-white px-4 py-2 rounded-xl md:mt-0 md:absolute"}>
                        AI leser fortsatt salgsoppgaven...
                    </motion.div>
                )}

                {/*------------------------- PAGE CONTENT -------------------------*/}
                <div className="mt-5 font-medium space-y-10">
                    <MetadataSection/>
                    <div>
                        <PageHeading>Bemerkninger</PageHeading>
                        <p>Dette er bemerkninger AISalgsoppgave gjorde da den leste salgsoppgaven</p>

                        <div className={"w-full grid xl:grid-cols-2"}>
                            <RemarksSection/>
                        </div>
                    </div>


                    {/*------------------------- MORE DATA COMING LATER -------------------------*/}
                    <div className={"grid grid-cols-3 !my-28 justify-center items-center"}>
                        <hr className={"w-full border-2 border-main"}/>
                        {validSubscription ? (
                            <div>
                                <h2 className={"text-center text-3xl text-main"}>Kommer snart</h2>
                                <p className={"text-center py-1"}>Ditt
                                    <span className={"text-yellow-500 font-bold"}>&nbsp;pro&nbsp;</span>
                                    abonnement vil snart gi deg eksklusiv tilgang til mer funksjonalitet.
                                </p>
                            </div>
                            ) : (
                            <div>
                                <h2 className={"text-center text-3xl text-main"}>Vil du se mer data?</h2>
                                <p className={"text-center py-1"}><Link to={"/abonnement"} className={"text-main underline"}>Start en prøveperiode</Link> og få tidlig tilgang</p>
                            </div>
                        )}
                        <hr className={"w-full border-2 border-main"}/>
                    </div>
                    <img className={"w-full opacity-50 blur-sm"} src={"/templates/tilstandsgrad.jpg"}/>
                    <img className={"w-full opacity-50 blur-sm"} src={"/templates/om_bolig_og_naeromraade.jpg"}/>
                    <img className={"w-full opacity-50 blur-sm"} src={"/templates/egenerklaering.jpg"}/>

                    {/*
                    <TechnicalStateSection className="mt-10"/>
                    <StateSection className="mt-10"/>
                    <div className=""></div>
                    */}
                </div>
            </div>
        </SkeletonTheme>
    );
};

export default MainDashboard;
